import React from 'react'
import Responsive from '../../../../HOC/Responsive'

function LeftQuote(props) {
  const {isMobile = false} = props
  return (
    <svg
      width={isMobile ? 14 : 24}
      height={isMobile ? 11 : 20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 20"
    >
      <path
        d="M10.37.112C6.117.412.002 1.09 0 9.42V19.9h9.49V8.686H6.34c-.2-2.994 2.266-3.767 4.947-4.36L10.37.112Zm12.714 0c-4.253.3-10.366.978-10.37 9.307V19.9h9.49V8.686h-3.15c-.2-2.994 2.265-3.767 4.946-4.36L23.084.112Z"
        fill="#3F3F3F"
      />
    </svg>
  )
}

export default Responsive(LeftQuote)
