import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;

  .quote-wrapper {
    width: 80%;
    padding-top: 20px;
    position: relative;
  }

  .quote-container {
    white-space: normal;
    width: 100%;
    font-style: normal;
    margin-bottom: 12px;
    text-align: center;
    color: var(--brand-font-default-color);
    height: auto;
  }

  .quote-person-description {
    width: 100%;
    margin: 12px 0;
    font-family: var(--brand-font-family-text);
    font-style: normal;
    font-weight: var(--brand-font-weight-normal);
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    text-wrap: wrap;
    color: var(--brand-font-default-color);
  }

  .quote-person-name {
    font-weight: var(--brand-font-weight-heavy);
  }

  .quote-left {
    position: absolute;
    top: 0;
    left: -20px;
  }

  .quote-right {
    position: absolute;
    top: 0;
    right: -20px;
  }

  @media (min-width: 768px) {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    .quote-wrapper {
      width: 100%;
      max-width: 546px;
      min-width: 40%;
      padding: 50px;
    }

    .quote-container {
      min-height: unset;
      color: var(--brand-font-default-color);
    }

    .quote-person-description {
      margin-top: 56px;
      font-size: 18px;
      line-height: 25px;
    }

    .quote-left {
      position: absolute;
      top: 50px;
      left: 25px;
    }

    .quote-right {
      position: absolute;
      top: 50px;
      right: 25px;
    }

    > * {
      flex: 0 0 47%;
    }
  }
`
