import styled from 'styled-components'

export const RichProductCardsWapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 16px;
  background: ${(props) => props.bgColor};
  margin-bottom: 56px;

  .section-heading {
    font-style: normal;
    text-transform: capitalize;
    color: var(--brand-font-default-color);

    > * {
      margin: 0;
      padding: 0;
    }
  }

  .section-description {
    font-style: normal;
    color: var(--brand-font-default-color);
    margin-bottom: 38px;
    margin-top: var(--dls-size-8);
  }

  .section-carousel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
  }

  .image-gallery-bullets {
    position: absolute;
    bottom: -5px;
    z-index: 0;
  }

  .image-gallery-bullets-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-gallery-bullets .image-gallery-bullet {
    border: none;
    //todo: add this to theme
    background-color: rgba(211, 218, 228, 1);
    width: 6px;
    height: 6px;
    box-shadow: none;
    margin-left: 9px;
  }

  .image-gallery-bullets .image-gallery-bullet:first-child {
    margin-left: 0;
  }

  .image-gallery-bullets .image-gallery-bullet.active {
    //todo: add this to theme
    background: rgba(110, 120, 139, 1);
    border: none;
    width: 12px;
    height: 12px;
  }

  @media (min-width: 768px) {
    padding: 30px 16px;
    padding-bottom: 50px;
    margin-bottom: 96px;

    .section-heading {
      margin-bottom: 12px;
      width: 100%;
      text-align: left;
      max-width: 1125px;
    }

    .section-description {
      color: var(--brand-font-default-color);
      margin-bottom: 60px;
      width: 100%;
      text-align: left;
      max-width: 1125px;
    }

    .image-gallery-bullets {
      bottom: -40px;
      display: none;
    }

    .image-gallery-bullets .image-gallery-bullet {
      width: 10px;
      height: 10px;
    }
    .image-gallery-bullets .image-gallery-bullet.active {
      width: 20px;
      height: 20px;
    }

    .image-gallery-bullets .image-gallery-bullet {
      margin-left: 15px;
    }
  }
`
