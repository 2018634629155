import React from 'react'
import Responsive from '../../../../HOC/Responsive'

function RightQuote(props) {
  const {isMobile = false} = props
  return (
    <svg
      width={isMobile ? 14 : 24}
      height={isMobile ? 11 : 20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 20"
    >
      <path
        d="M13.63 19.888c4.253-.3 10.367-.978 10.37-9.307V.1h-9.49v11.213h3.151c.2 2.993-2.266 3.767-4.947 4.36l.916 4.214Zm-12.714 0c4.253-.3 10.366-.978 10.37-9.307V.1h-9.49v11.213h3.15c.2 2.993-2.265 3.767-4.946 4.36l.916 4.214Z"
        fill="#3F3F3F"
      />
    </svg>
  )
}

export default Responsive(RightQuote)
