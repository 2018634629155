import React from 'react'
import {RightArrow} from '../../../../common/Arrows'
import {StyledRightNav} from './styles'
import PropTypes from 'prop-types'

const RightNav = (props) => {
  const {onclick = () => {}, disabled = true, className = ''} = props
  return (
    <StyledRightNav
      data-disabled={disabled}
      onClick={onclick}
      className={className}
    >
      <RightArrow />
    </StyledRightNav>
  )
}

// @ts-ignore
RightNav.propTypes = {
  onclick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
}

export default RightNav
