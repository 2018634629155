import React, {useMemo, useCallback} from 'react'
import ImageGallery from 'react-image-gallery'
import noop from 'lodash/noop'

import RenderItem from './RenderItem'
import LeftNav from './LeftNav'
import RightNav from './RightNav'
import {RichProductCardsWapper} from './RichProductsCards.styles'
import Responsive from '../../../HOC/Responsive'
import ProductListingContext from '../../../Context/ProductListingContext'
import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'
import Typography from '../../../common/Typography/Typography'

function RichProductsCards(props) {
  const {
    sectionName = '',
    sectionHeading = '',
    description = '',
    cards = [],
    bgColor = '#FEEED8',
    themeToUse = '',
    productListingConfig = {},
  } = props.state || {}
  const {onProductClick = noop, onAddToCart = noop} = props.actions || {}
  const renderItem = useCallback(() => {
    return (item) => {
      return (
        <RenderItem
          cardData={item}
          onProductClick={onProductClick}
          onAddToCart={onAddToCart}
          sectionName={sectionName}
        />
      )
    }
  }, [onProductClick, onAddToCart, sectionName])

  const mappedImages = useMemo(() => {
    return cards.map((card, index) => {
      return {
        ...card,
        renderItem: renderItem(index),
      }
    })
  }, [cards, renderItem])

  const {isMobile = false} = props
  const isNavShow = !isMobile

  if (!cards || !cards.length) return null
  return (
    <RichProductCardsWapper
      bgColor={bgColor}
      data-section={sectionName}
      data-category={themeToUse}
    >
      <Typography
        text={sectionHeading}
        variant="heading-sm-bold"
        customClassName="section-heading"
        headingLevel={2}
        isDangerouslySet={true}
      />
      {!!description && (
        <Typography
          text={description}
          variant="body-base-regular"
          customClassName="section-description"
          isDangerouslySet={true}
        />
      )}
      <div className="section-carousel">
        <ProductListingContext.Provider value={productListingConfig}>
          <ImageGallery
            showNav={isNavShow}
            showThumbnails={false}
            showFullscreenButton={false}
            infinite={true}
            slideInterval={5000}
            autoPlay={false}
            showPlayButton={false}
            showBullets={cards.length >= 2}
            items={mappedImages}
            renderLeftNav={(onClick, disabled) => {
              return <LeftNav disabled={disabled} onclick={onClick} />
            }}
            renderRightNav={(onClick, disabled) => {
              return <RightNav disabled={disabled} onclick={onClick} />
            }}
          />
        </ProductListingContext.Provider>
      </div>
    </RichProductCardsWapper>
  )
}

export default withCustomErrorBoundary(
  Responsive(RichProductsCards),
  'RichProductsCards'
)
