import React from 'react'
import noop from 'lodash/noop'
import ProductCard from '../ProductCard'
import {Wrapper} from './styles'
import LeftQuote from '../LeftQuote'
import RightQuote from '../RightQuote'
import Typography from '../../../../common/Typography/Typography'

function RenderItem(props) {
  const {
    cardData,
    onProductClick = noop,
    onAddToCart = noop,
    sectionName,
  } = props
  const {
    person_quote = '',
    person_name = '',
    person_designation = '',
  } = cardData || {}

  return (
    <Wrapper>
      <div className="quote-wrapper">
        <div className="quote-container">
          <Typography text={person_quote} variant="body-base-regular" />
        </div>
        <div className="quote-person-description">
          <label className="quote-person-name">{person_name}</label>
          {` | `}
          <label className="quote-person-designation">
            {person_designation}
          </label>
        </div>
        <div className="quote-left">
          <LeftQuote />
        </div>
        <div className="quote-right">
          <RightQuote />
        </div>
      </div>
      <ProductCard
        productData={cardData}
        onProductClick={onProductClick}
        onAddToCart={onAddToCart}
        sectionName={sectionName}
      />
    </Wrapper>
  )
}

export default RenderItem
