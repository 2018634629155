import React, {useEffect, useRef, useCallback, useContext, useMemo} from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import {CardWrapper} from './styles'
import RatingStars from '../../../../common/RatingStars/ratingStars'
import ProductListingContext from '../../../../Context/ProductListingContext'
import OptimizedImage from '../../../../common/OptimizedImage'
import {useCartLoadingContext} from '../../../../Context/CartUpdatingContext'
import {Spinner} from '../../../../common'
import {useInternationalizationContext} from '../../../../Context/InternationalizationContext'
import Typography from '../../../../common/Typography/Typography'

function ProductCard({
  productData,
  isMobile,
  onProductClick = noop,
  onAddToCart = noop,
  sectionName,
}) {
  const {
    name,
    rating,
    discountText,
    price,
    image = '',
    discountedPrice,
    reviewVideo = '',
    category = '',
    whyThisWorks = [],
    videoImageAspects,
    sku = '',
  } = productData || {}

  const spinnerColor = 'white'

  const {isCartUpdating, itemBeingAddedToCart} = useCartLoadingContext()
  const {currency} = useInternationalizationContext()

  const videoElemRef = useRef(null)
  const ifProductOnDiscount = !!discountedPrice
  const {height = '400', width = '400'} = videoImageAspects || {}

  const showLoader = useMemo(() => {
    return isCartUpdating && itemBeingAddedToCart === sku
  }, [isCartUpdating, itemBeingAddedToCart, sku])

  const attemptPlay = useCallback(() => {
    videoElemRef &&
      videoElemRef.current &&
      videoElemRef.current.play().catch(() => {
        console.error('Error attempting to play')
      })
  }, [videoElemRef])

  const handleProductCardClick = useCallback(() => {
    onProductClick(productData, sectionName)
  }, [onProductClick, productData, sectionName])

  const addItemToCart = useCallback(
    (e) => {
      e.stopPropagation()
      onAddToCart(productData, sectionName)
    },
    [productData, onAddToCart, sectionName]
  )

  useEffect(() => {
    attemptPlay()
  }, [attemptPlay])

  const productListingConfig = useContext(ProductListingContext)
  const {addToCartBtnConfig = {}} = productListingConfig
  const {
    label: btnLabel = 'Learn More',
    action = 'redirect',
    isDisabled: isATCDisabled = false,
  } = addToCartBtnConfig || {}

  const ctaAction = useCallback(
    (e) => {
      action === 'redirect' ? handleProductCardClick() : addItemToCart(e)
    },
    [addItemToCart, handleProductCardClick, action]
  )

  if (!productData || isEmpty(productData)) return null
  return (
    <CardWrapper
      onClick={handleProductCardClick}
      data-is-review-video={!reviewVideo}
      data-category={category}
    >
      <div className="videoCont">
        {!!reviewVideo ? (
          <video
            autoPlay
            muted
            loop
            playsInline
            preload="metadata"
            width={width}
            height={height}
            className="autoplayVideo"
            ref={videoElemRef}
            loading="lazy"
          >
            <source src={reviewVideo} type="video/mp4" />
          </video>
        ) : (
          <OptimizedImage
            source={image}
            aspectWidth={width}
            aspectHeight={height}
            desktopViewWidth="THIRD"
            mobileViewWidth="THIRD"
            altText={name}
            loading="lazy"
            customClassName="autoplayVideo"
          />
        )}
      </div>
      <div className="productDetail__new_design">
        <div className="ratingsReview">
          <RatingStars
            rating={rating}
            height={isMobile ? '11' : '18'}
            width={isMobile ? '12' : '20'}
          />
        </div>
        <label className="productName">{name}</label>
        <div className="prices">
          <div className="discountP">
            <sup className="rupeeSup">{currency}</sup>
            {ifProductOnDiscount ? discountedPrice : price}
          </div>
          {ifProductOnDiscount && (
            <div className="actualP">
              {currency}
              {price}
            </div>
          )}
          {ifProductOnDiscount && (
            <div className="discountText">{discountText}</div>
          )}
        </div>
        <div className="whyThisWorks">
          {whyThisWorks.map(({label, text}) => {
            if (!label || !text) return null
            return (
              <div key={label} className="why-this-work-item">
                <span className="key">{label}</span>
                {text}
              </div>
            )
          })}
        </div>
      </div>
      {!isATCDisabled && (
        <div className="cartAdd" onClick={ctaAction}>
          <label className="cardAdd-label">
            {showLoader ? (
              <Spinner color={spinnerColor} />
            ) : (
              <Typography text={btnLabel} variant="cta-label-sm" />
            )}
          </label>
        </div>
      )}
    </CardWrapper>
  )
}

export default ProductCard
