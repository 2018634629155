import React from 'react'
import {LeftArrow} from '../../../../common/Arrows'
import {StyledLeftNav} from './styles'
import PropTypes from 'prop-types'

const LeftNav = (props) => {
  const {onclick = () => {}, disabled = true, className = ''} = props
  return (
    <StyledLeftNav
      data-disabled={disabled}
      onClick={onclick}
      className={className}
    >
      <LeftArrow />
    </StyledLeftNav>
  )
}

LeftNav.propTypes = {
  onclick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
}

export default LeftNav
