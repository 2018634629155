import styled from 'styled-components'

export const StyledRightNav = styled.div`
  position: absolute;
  top: calc(50% - 60px);
  z-index: 1;
  right: -100px;
  cursor: pointer;

  &[data-disabled='true'] {
    display: none;
  }
`
